import React from "react";

import Layout from "../components/layout";

const pageTitle = "404: Not found";

const NotFoundPage = () => (
    <Layout pageTitle={pageTitle}>
        <h5 className="my-5">NOT FOUND</h5>
        <div>
            You just hit a route that doesn't exist...
        </div>
        <div className="mb-5">
            <span className="mr-1">If you believe this is in error, please email the link to</span>
            <a href="mailto:supprt@sqlapi.com">supprt@sqlapi.com</a>
        </div>
    </Layout>
);

export default NotFoundPage;
